import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { DropTargetMonitor, XYCoord, useDrop } from "react-dnd";
import { carsSings, streetSigns, trafficSigns, especialCarsSings, peopleSings,objectSings, arrowSigns, animalsSigns } from "../data";
import BoardFooter from './BoardFooter';
import { Element as CustomElement } from "./Element/types";
import { v4 as uuid } from "uuid";
import Moveable from "react-moveable";
import '../index.css'
import { MAX_COLUMN, MAX_ROW, WIDTH_AND_HEIGHT } from "../constants";
import { useProject } from '../context/ProjectContext';
import ElementDetailsModal from './ElementDetailsModal';

interface boardProps {
  onDrop: (item: CustomElement) => void;
  items: Array<CustomElement>;
  isShowGrid?: boolean;
  image: string | null;
  setItems: React.Dispatch<React.SetStateAction<CustomElement[]>>;
  setBoardItems: React.Dispatch<React.SetStateAction<CustomElement[]>>;
}

export const Board = React.forwardRef<any, boardProps>((props, ref) => {
  const { onDrop, items, image, setItems, setBoardItems } = props;
  const [selectedElement, setSelectedElement] = useState<CustomElement | null>(null);
  const [frame, setFrame] = useState({
    translate: [0, 0],
    rotate: 0,
    width: 100,
    height: 100,
  });
  const [color] = useState<string>('#fff');
  const svgCanvasRef = useRef<SVGSVGElement | null | any>(ref);
  const selectedElementRef = useRef<SVGImageElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const acceptedTypes = trafficSigns
    .concat(streetSigns)
    .concat(carsSings)
    .concat(especialCarsSings)
    .concat(peopleSings)
    .concat(objectSings)
    .concat(arrowSigns)
    .concat(animalsSigns)
    .map((sign) => sign.source);

  const [, drop] = useDrop(
    () => ({
      accept: acceptedTypes,
      drop(item: CustomElement, monitor) {
        const delta = monitor.getClientOffset() as XYCoord;
        const left = Math.round(delta.x / 100) * 100 - 400;
        const top = Math.round(delta.y / 100) * 100 - 200;
        onDrop({
          left: left,
          top: top,
          source: monitor.getItemType() as string,
          height: item.height,
          width: item.width,
          id: item.id,
          icon: item.icon,
          name: item.name,
          originalSource: item.source,
          selectedColor: '#fff',
        });
        return undefined;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggingColor: monitor.getItemType() as string,
      }),
    }),
    [onDrop]
  );

  drop(svgCanvasRef);

  const handleColorChange = async (color: any) => {
    if (selectedElementRef.current) {
      const href = selectedElementRef.current.getAttribute('href');
      if (href) {
        const response = await fetch(href);
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("image/svg+xml")) {
          let svgText = await response.text();
          svgText = svgText.replace(/\.st0{fill:[^;]*;/g, `.st0{fill:${color.hex};`);
          const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
          const newHref = URL.createObjectURL(svgBlob);
          selectedElementRef.current.setAttribute('href', newHref);

          const updatedItems = items.map(item => {
            if (item.id === selectedElement?.id) {
              return { ...item, source: newHref, selectedColor: color.hex };
            }
            return item;
          });
          setItems(updatedItems);
          setBoardItems(updatedItems);
        }
      }
    }
  };

  const handleDelete = useCallback(() => {
    if (selectedElement) {
      const updatedItems = items.filter(item => item.id !== selectedElement.id);
      setSelectedElement(null);
      setItems(updatedItems);
      setBoardItems(updatedItems);
    }
  }, [selectedElement, items, setItems, setBoardItems]);

  const updateElementDetails = (id: string, name: string, description: string) => {
    const updatedItems = items.map(item => {
      if (item.id === id) {
        return { ...item, name, description };
      }
      return item;
    });
    setItems(updatedItems);
    setBoardItems(updatedItems);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        handleDelete();
      } else if (event.key === 'Escape') {
        setSelectedElement(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedElement, items, handleDelete]);

  return (
    <>
      <svg
        ref={svgCanvasRef}
        width={MAX_COLUMN * WIDTH_AND_HEIGHT}
        height={MAX_ROW * WIDTH_AND_HEIGHT}
        style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}
      >
        {items.map((item, key) => {
          const { left, top, source, height, width, transform, name } = item;
          const isArrow = item.type === 'arrow';
          return (
            <g key={key}>
              <image
                ref={selectedElementRef}
                href={source}
                x={!isDragging ? left : 0}
                y={!isDragging ? top : 0}
                height={`${height ?? 100}`}
                width={`${width ?? 100}`}
                style={{ 
                  cursor: "move", 
                  transformOrigin: `${left + width / 2}px ${top + height / 2}px`,
                  transform: transform
                }}
                onMouseDownCapture={(event) => {
                  setSelectedElement(item);
                  setFrame({
                    translate: [left, top],
                    rotate: 0,
                    width: width,
                    height: height,
                  });
                  selectedElementRef.current = event.currentTarget;
                }}
              />
              {isArrow && (
                <text
                  x={left + width / 2}
                  y={top - 10}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{ 
                    fill: 'black', 
                    fontSize: '12px',
                    transformOrigin: `${left + width / 2}px ${top + height / 2}px`,
                    transform: transform
                  }}
                >
                  {name}
                </text>
              )}
            </g>
          );
        })}
      </svg>
      {selectedElement && (
        <>
          <Moveable
            target={selectedElementRef.current}
            resizable
            rotatable
            draggable
            onResize={({ target, width, height }) => {
              if (selectedElement?.type === 'arrow') {
                console.log('resizing arrow');
              }

              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
              setFrame({
                ...frame,
                width,
                height,
              });
              target.style.transformOrigin = `${frame.translate[0] + width / 2}px ${frame.translate[1] + height / 2}px`;

              if (selectedElement) {
                const updatedItems = items.map(item =>
                  item.id === selectedElement.id ? { ...item, width, height } : item
                );
                setItems(updatedItems);
                setBoardItems(updatedItems);
              }
            }}
            onRotate={e => {
              e.target.style.transform = e.drag.transform;
              if (selectedElement) {
                const updatedItems = items.map(item =>
                  item.id === selectedElement.id ? { ...item, transform: e.drag.transform } : item
                );
                setItems(updatedItems);
                setBoardItems(updatedItems);
              }
            }}
            onDrag={e => {
              setIsDragging(true);
              e.target.style.transform = e.transform;
              const transformValues = e.transform.match(/translate\(([^)]+)\)/);
              if (transformValues) {
                const [x, y] = transformValues[1].split(',').map(value => parseFloat(value.trim()));
                console.log('New X:', x, 'New Y:', y);

                if (selectedElement) {
                  const updatedItems = items.map(item =>
                    item.id === selectedElement.id ? { ...item, transform: e.transform } : item
                  );
                  setItems(updatedItems);
                  setBoardItems(updatedItems);
                }
              }
              setIsDragging(false);
            }}
          />
          <BoardFooter 
            onDeselect={() => setSelectedElement(null)} 
            color={color} 
            handleColorChange={handleColorChange} 
            isSvgSource={selectedElement?.isSvg ?? false} 
            onDelete={handleDelete} 
            onAddDetails={() => setShowDetailsModal(true)}
          />
        </>
      )}
      {selectedElement && (
        <ElementDetailsModal
          showModal={showDetailsModal}
          setShowModal={setShowDetailsModal}
          element={selectedElement}
          updateElementDetails={updateElementDetails}
        />
      )}
    </>
  );
});

interface StateFulProps {
  image: string | null;
  boardItems: CustomElement[];
  setBoardItems: React.Dispatch<React.SetStateAction<CustomElement[]>>;
}

export const StatefulTargetBoard = forwardRef<any, StateFulProps>(
  (props, ref) => {
    useProject();
    const [items, setItems] = useState<CustomElement[]>([]);
    const { image, boardItems, setBoardItems } = props;

    const handleDrop = useCallback((item: CustomElement | CustomElement[]) => {
      if (Array.isArray(item)) {
        setItems(item);
        setBoardItems(item);
      } else {
        const newItems = [
          ...items,
          {
            id: uuid(),
            top: item.top,
            left: item.left,
            width: item.width,
            height: item.height,
            source: item.source,
            originalSource: item.source,
            selectedColor: '#fff',
            icon: item.icon,
            name: item.name,
            isSvg: item.source.endsWith('.svg'),
            description: item.description || '',
            type: item.type || item.source.split("/").at(-1)?.split(".")[0].replace(/\d$/, ''),
          },
        ];
        setItems(newItems);
        setBoardItems(newItems);
      }
    }, [items, setBoardItems]);

    useEffect(() => {
      setItems(boardItems);
    }, [boardItems]);    

    return <Board ref={ref} onDrop={handleDrop} items={items} image={image} setItems={setItems} setBoardItems={setBoardItems} />;
  }
);