// MapComponent.tsx
import { useEffect, useRef, useState } from "react"
import { Coordinates } from "./MapPage"
import { Marker } from "./Marker"
import { MAX_COLUMN, MAX_ROW, WIDTH_AND_HEIGHT } from '../constants';


export function MapComponent({
  center,
  zoom,
}: {
  center: Coordinates
  zoom: number
}) {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const ref = useRef<HTMLDivElement>(null)

  const width = MAX_COLUMN * WIDTH_AND_HEIGHT;
  const height = MAX_ROW * WIDTH_AND_HEIGHT;

  useEffect(() => {
    if (ref.current) {
      const newMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        fullscreenControl: false,
        mapTypeControl: false,
        panControl: false,
        streetViewControl: false,
      })
      setMap(newMap)
    }
  }, [center, zoom])

  useEffect(() => {
    if (map) {
      map.setCenter(center)
      map.setZoom(zoom)
    }
  }, [center, map, zoom])

  return (
    <div className="map-container" style={{ height: `${height}px`, width: `${width}px` }} ref={ref}>
      {map && <Marker coordinates={center} map={map} />}
    </div>
  )
}