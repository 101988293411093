import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

interface BoardFooterProps {
  onDeselect: () => void;
  color: string;
  handleColorChange: (color: any) => void;
  isSvgSource: boolean;
  onDelete: () => void;
  onAddDetails: () => void;
}

const BoardFooter: React.FC<BoardFooterProps> = ({ onDeselect, color, handleColorChange, isSvgSource, onDelete, onAddDetails }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChangeAndClose = (color: any) => {
    handleColorChange(color);
    handleClose();
  };

  const popover = {
    position: 'absolute',
    bottom: '60px',
    left: '0',
    transform: 'translateX(0)',
    zIndex: '20',
  } as React.CSSProperties;

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  } as React.CSSProperties;

  return (
    <div className="board-footer">
      <nav>
        <ul>
          <li>
            <button onClick={onDeselect}>
              Deseleccionar
            </button>
          </li>
          {isSvgSource && (
            <li>
              <button onClick={handleClick}>Cambiar Color</button>
            </li>
          )}
          <li>
            <button onClick={onAddDetails}>Agregar Detalles</button>
          </li>
          <li>
            <button onClick={onDelete}>Eliminar</button>
          </li>
        </ul>
      </nav>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleColorChangeAndClose} />
        </div>
      ) : null}
    </div>
  );
};

export default BoardFooter;