import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ServerContextProps {
  servidor: string | null;
  id: string | null;
  setServidor: (servidor: string | null) => void;
  setId: (id: string | null) => void;
}

const ServerContext = createContext<ServerContextProps | undefined>(undefined);

export const ServerProvider = ({ children }: { children: ReactNode }) => {
  const [servidor, setServidor] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(null);

  return (
    <ServerContext.Provider value={{ servidor, id, setServidor, setId }}>
      {children}
    </ServerContext.Provider>
  );
};

export const useServer = () => {
  const context = useContext(ServerContext);
  if (!context) {
    throw new Error('useServer must be used within a ServerProvider');
  }
  return context;
};
